import React, { lazy, Suspense, useEffect, useState } from "react";
import "./App.css";
import { Route, Routes, useNavigate } from "react-router-dom";
import Loader from "./components/loader/Loader";
import { useSelector } from "react-redux";
import app from "./services/feathers";
import { message } from "antd";
import socket from "./services/socket";
const AuthRedirect = lazy(() => import("./pages/redirect/AuthRedirect"));
const Home = lazy(() => import("./pages/home/Home"));
const Signin = lazy(() => import("./pages/authentication/signin/Signin"));
const Signup = lazy(() => import("./pages/authentication/signup/Signup"));
const SignupAgents = lazy(() =>
  import("./pages/authentication/signup/SignupAgents")
);
const ChangePasswordView = lazy(() =>
  import("./pages/authentication/change-password/ChangePasswordView")
);
const ResetPassswordView = lazy(() =>
  import("./pages/authentication/change-password/ResetPassswordView")
);
const NotFound = lazy(() => import("./pages/not-found/NotFound"));
const DashboardLayout = lazy(() => import("./layouts/DashboardLayout"));
const TransactionMortgage = lazy(() =>
  import("./pages/transaction-morgage/TransactionMortgage")
);
const TermsAndConditions = lazy(() =>
  import("./pages/terms-and-conditions/TermsAndConditions")
);
const PrivacityPolicy = lazy(() =>
  import("./pages/privacy-police/PrivacityPolice")
);

const App = () => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  let user = JSON.parse(window.localStorage.getItem("user"));
  let accessToken = localStorage.getItem("feathers-jwt");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const connect = ({ strategy = "jwt", accessToken, ...rest }) => {
    return socket.authenticate({
      strategy,
      accessToken,
      ...rest,
    });
  };
  useEffect(() => {
    // Función para manejar la reautenticación
    const reauthenticate = async () => {
      try {
        if (accessToken) {
          // Autentica automáticamente usando el token almacenado
          await app.authenticate({
            strategy: "jwt", // Usualmente es "jwt" para tokens de acceso
            accessToken: accessToken, // El token almacenado
          });
          if (!user) {
            localStorage.removeItem("feathers-jwt");
            localStorage.removeItem("trestle-token");
          }
          // Si la autenticación es exitosa, actualiza el estado solo si es necesario
          if (!isAuthenticated) {
            setIsAuthenticated(true);
            connect({
              accessToken,
            });
          }
        } else {
          // Si no hay token, muestra un mensaje o redirige
          // navigate("/signin");
        }
      } catch (error) {
        message.error(error.message);
        if (error.name === "NotAuthenticated") {
          message.error("Your session has expired. Please log in again.");
          if (isAuthenticated) {
            setIsAuthenticated(false);
          }
          navigate("/signin");
        }
      }
    };
    reauthenticate();
  }, [accessToken, isAuthenticated, navigate]); // Asegúrate de que isAuthenticated y navigate sean necesarios aquí
  useEffect(() => {
    if (auth?.user?.theme) {
      document.documentElement.setAttribute("data-theme", auth.user.theme);
    }
  }, [auth.user]);
  return (
    <Suspense fallback={<Loader />}>
      <AuthRedirect />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/transaction-mortgage-purchase"
          element={<TransactionMortgage />}
        />
        <Route path="/signin" element={<Signin />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/signup-agents" element={<SignupAgents />} />
        <Route path="/reset-password" element={<ResetPassswordView />} />
        <Route
          path="/change-password/:token"
          element={<ChangePasswordView />}
        />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/privacy-policy" element={<PrivacityPolicy />} />
        <Route path="/dashboard/*" element={<DashboardLayout />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};

export default App;
